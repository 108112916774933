import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faTwitter,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons";
import { useForm } from "react-hook-form";
import { useLanguageIdentifier } from "hooks/Forms/useLanguageIdentifier";
import { useLocation } from "@reach/router";
import Loader from "components/loader";
import formCommonPostData from "utils/form-common-post-data";

const LanguageIdentifier = () => {
  const { status, mutateAsync } = useLanguageIdentifier();
  const location = useLocation();
  const [toggleTool, setToggleTool] = useState(false);
  const [summarizedText, setSummarizedText] = useState("");
  const [detectedLanguage, setDetectedLanguage] = useState("");

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    if (data["url"] == "" || data["message"] == "") {
      alert("Please enter  text in box");
      return false;
    }

    const postData1 = {
      // url: data.url,
      text: data.message,
      // pagelink: location.href,
    };
    const postData = { ...postData1, ...formCommonPostData() };
    mutateAsync(postData).then((response) => {
      if (response.success == true) {
        let finalText = "";
        const detectedLang = response.data.language_probability.name;
        setDetectedLanguage(detectedLang);

        const obj = response.data.language_probability.map((k) => {
          const [key] = Object.entries(k);

          finalText += ` Most Likely ${key[0]} - ${Math.ceil(key[1] * 100)}%. `;

          setSummarizedText(finalText);
        });

        setToggleTool(true);
        reset();
        window.scrollTo(0, 0);
      } else {
        alert(response.message);
      }
    });
  };
  return (
    <div className="bg-darkBlue text-white font-sans">
      {/* <div className="w-11/12 sm:w-8/12 lg:w-6/12 max-w-4xl mx-auto">
        <h1 className="text-[32px] sm:text-[40px] md:text-[48px] lg:text-[52px] bg-contact-img custom-bg-pos bg-no-repeat leading-[40px] lg:leading-[60px] inline-block font-medium w-auto lg:w-max font-riot custom-bg-h1 mb-4 md:text-center my-16">
          Detect Any Language with Tomedes
        </h1>
      </div> */}
      <div className="py-[4rem] px-3">
        <div className="sm:bg-newBlueStripBg relative h-[2rem] lg:w-[56rem] m-auto">
          <h1 className="text-[32px] sm:text-[40px] md:text-[48px] lg:text-[52px] font-riot absolute">
            {" "}
            Detect Any Language with Tomedes
          </h1>
        </div>
      </div>

      <div className="w-11/12 sm:w-10/12 lg:w-8/12 max-w-6xl mx-auto">
        <div className="mt-12">
          <h2 className="text-[24px] mb-6">Your Language Identifier Tool </h2>
          {!toggleTool && (
            <p className="text-[21px] font-light mb-6">
              The Tomedes Language Detector is designed to help you identify the
              language of a text. Input the text, and easily find out what
              language your text is in. (There is a possibility that it can
              detect multiple languages).
            </p>
          )}
          {toggleTool && (
            <div className="grid grid-flow-col lg:grid-cols-1 gap-7 mb-8">
              <div>
                <p className="text-24 mb-3 font-bold flex lg:flex-row flex-col">
                  <span>Result : &nbsp; </span> <span>{summarizedText}</span>
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="w-11/12 sm:w-10/12 lg:w-8/12 max-w-6xl mx-auto">
        <div className="flex flex-col sm:flex-row gap-8">
          <a
            className="pl-3 pr-5 py-1 bg-[#306199] hover:bg-[#244872] transition-colors duration-300 flex gap-6 items-center font-bold rounded-sm w-36"
            href="https://www.linkedin.com/shareArticle?mini=true&url=https%3A%2F%2Fen.tomedes.com%2Fsummary-tool"
            target="_blank"
          >
            <FontAwesomeIcon
              className=" text-17 inline-block text-white"
              icon={faFacebookF}
            />
            Facebook
          </a>
          <a
            className="pl-3 pr-5 py-1 bg-[#007bb6] hover:bg-[#005983] transition-colors duration-300 flex gap-6 items-center font-bold rounded-sm w-36"
            href="https://www.linkedin.com/shareArticle?mini=true&url=https%3A%2F%2Fen.tomedes.com%2Fsummary-tool"
            target="_blank"
          >
            <FontAwesomeIcon
              className=" text-17 inline-block text-white"
              icon={faLinkedinIn}
            />
            Linkedin
          </a>
          <a
            className="pl-3 pr-5 py-1 bg-[#26c4f1] hover:bg-[#0eaad6] transition-colors duration-300 flex gap-6 items-center font-bold rounded-sm w-36"
            href="https://twitter.com/share?text=An%20interesting%20translation%20conference&url=https%3A%2F%2Fen.tomedes.com%2Fsummary-tool"
            target="_blank"
          >
            {/* <FontAwesomeIcon
              className=" text-17 inline-block text-white"
              icon={faTwitter}
            /> */}
            <svg
              // className="social_media_icon_hover"
              fill=""
              width="40"
              zoomAndPan="magnify"
              viewBox="0 0 375 374.999991"
              height="40"
            >
              <defs>
                &lt; d="M 8.917969 7.773438 L 367.417969 7.773438 L 367.417969
                366.273438 L 8.917969 366.273438 Z M 8.917969 7.773438 " /&gt;{" "}
              </defs>

              <g transform="translate(90,80) ">
                {" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 44 44"
                  version="1.1"
                  height="full"
                  width="full"
                >
                  <path
                    d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"
                    fill="#ffffff"
                  ></path>
                </svg>
              </g>
            </svg>
            Twitter
          </a>
        </div>
        <form
          action="
        "
          className="mt-12 pb-[64px]"
          onSubmit={handleSubmit(onSubmit)}
        >
          <p className="text-[21px] font-light mb-6">
            Copy and paste your text into the box:
          </p>
          <textarea
            name="message"
            id="message"
            cols="30"
            rows="10"
            {...register("message")}
            className="px-5 py-2 text-black font-sans w-full rounded-lg"
          ></textarea>
          <span className="text-white">Max chars: 100,000</span>
          {/* <input         
            type="hidden"
            id="zc_gad"
            name="zc_gad"
            value=""
          /> */}
          {detectedLanguage && (
            <input
              className="text-black mt-4 px-4 ml-2"
              type="text"
              id="zc_gad"
              name="zc_gad"
              value={detectedLanguage}
              readOnly
            />
          )}
          <button className="bg-lightBlue text-[20px] text-center py-1 mt-9 rounded-md block w-40 ml-auto">
            {status === "loading" ? <Loader /> : "Continue"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default LanguageIdentifier;
