import LanguageIdentifier from "components/languageIdentifier";
import Layout from "components/layout";
import React from "react";
import Seo from "components/seo";

const index = () => {
  return (
    <Layout>
      <Seo
        title="Language Detector - Language Identifier | Tomedes"
        description="Automatic language identifier - Use our accurate detector and helpful tool to identify languages. Detect more than 180 languages."
        slug="/tools/language-detector"
      />
      <LanguageIdentifier />
    </Layout>
  );
};

export default index;
